import { AnyAction } from '@reduxjs/toolkit';

import * as paymentActions from './payments/PaymentActions';
import * as uiActions from './ui/UiActions';
import * as userActions from './user/UserActions';

export enum AppActionTypes {
  REDUX_ACTION = 'app/REDUX_ACTION',
  EXTERNAL_REDUX_ACTION = 'app/EXTERNAL_REDUX_ACTION',
}

export interface AppAction {
  type: AppActionTypes;
  payload: AnyAction;
}

export const actions = {
  ui: uiActions,
  user: userActions,
  payment: paymentActions,
};
