import { captureException, setUser } from '@sentry/nextjs';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes } from 'firebase/auth';
import { toast } from 'react-toastify';

import { AppUser } from 'types/user';

export const handleError = (e: unknown) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  if (process.env.NODE_ENV === 'production') {
    captureException(e);
  }
};
export const setUserContext = (user: Partial<AppUser>) => {
  if (process.env.GATSBY_APP_CONFIG === 'production') {
    setUser({
      uid: user.uid,
      email: user.email || undefined,
    });
  }
};

export const maybeShowToast = (error: Error | unknown) => {
  if (error instanceof FirebaseError) {
    if (error.code === AuthErrorCodes.USER_DELETED) {
      toast.error('User not found');
    }
    if (error.code === AuthErrorCodes.USER_DISABLED) {
      toast.error('User disabled');
    }
    if (error.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
      toast.error('Too many attempts');
    }
    if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
      toast.error('Wrong password');
    }
    if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
      toast.error('User with this email already exists');
    }
    if (error.code === AuthErrorCodes.POPUP_BLOCKED) {
      toast.error('Popup blocked');
    }
    if (error.code === AuthErrorCodes.NEED_CONFIRMATION) {
      toast.error('User with this email already exists');
    }
  }
};
