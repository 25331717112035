import { EcommerceEvent, UaEvent } from 'types/ga';
import { IProduct } from 'types/payments';

class GoogleUA {
  private analyticsEnabled = false;

  constructor() {
    if (
      process.env.NODE_ENV === 'production' &&
      typeof window !== 'undefined'
    ) {
      window.dataLayer = window.dataLayer ? window.dataLayer : [];
      this.analyticsEnabled = true;
    } else {
      // eslint-disable-next-line no-console
      console.warn('Google Analytics is disabled.');
    }
  }

  private logEvent = (e: EcommerceEvent) => {
    if (this.analyticsEnabled) {
      window.dataLayer?.push(e);
    }
  };

  trackBeginCheckout = (product: IProduct) => {
    this.logEvent({
      event: UaEvent.begin_checkout,
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          actionField: {
            step: 1,
          },
          products: [
            {
              id: product.stripeId,
              name: product.stripeId,
              price: product.price.toString(),
              brand: 'imageasy',
              category: 'SaaS',
              variant: product.period,
              quantity: 1,
            },
          ],
        },
      },
    });
  };

  trackPurchase = (product: IProduct) => {
    this.logEvent({
      event: UaEvent.purchase,
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          actionField: {
            id: new Date().getTime().toString(10),
            tax: '0',
            affiliation: 'Website',
            revenue: product.price.toString(),
            shipping: '0',
            coupon: '(not set)',
          },
          products: [
            {
              id: product.stripeId,
              name: product.stripeId,
              price: product.price.toString(),
              brand: 'imageasy',
              category: 'SaaS',
              variant: product.period,
              quantity: 1,
              coupon: '',
            },
          ],
        },
      },
    });
  };
}

export const AnalyticsUA = new GoogleUA();
