import { takeLatest } from 'typed-redux-saga';

import { actions } from './actions';
import { goToPayment } from './payments/PaymentSagas';
import {
  fetchUser,
  loginWithEmail,
  signInWithGithub,
  signInWithGoogle,
  signUpWithEmail,
} from './user/UserSagas';

export function* rootSaga() {
  yield* fetchUser();
  yield* takeLatest(actions.user.loginWithEmail, loginWithEmail);
  yield* takeLatest(actions.user.signUpWithEmail, signUpWithEmail);
  yield* takeLatest(actions.user.signInWithGithub, signInWithGithub);
  yield* takeLatest(actions.user.signInWithGoogle, signInWithGoogle);

  yield* takeLatest(actions.payment.goToPayment, goToPayment);
}
