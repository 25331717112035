import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { reducers } from './reducers';
import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware],
  devTools: true,
});
export type AppState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);
