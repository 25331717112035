import Image from 'next/image';
import { useTheme } from 'styled-components';

import { Text } from 'components/text/Text';

import { Box } from './Box';
import { Content } from './Content';
import { Section } from './Section';

export const Header = () => {
  const theme = useTheme();
  return (
    <Section
      as="header"
      zIndex="99"
      position="fixed"
      flexDirection="column"
      backgroundColor="bgHeader"
      style={{ backdropFilter: 'blur(8px)' }}
    >
      <Section
        padding="0.35rem"
        alignItems="center"
        flexDirection="column"
        backgroundColor="accent"
      >
        <Text
          color="white"
          whiteSpace="text-nowrap"
          fontSize="regular1"
          fontWeight="bold"
        >
          GET DISCOUNT:{' '}
          <span style={{ color: theme.colors.secondaryAccent }}>67% OFF</span>{' '}
          FOR YEARLY PLAN
        </Text>
      </Section>
      <Content height="64px" alignItems="center" justifyContent="space-between">
        <Box left="1rem">
          <Image
            alt="Logo"
            width={172}
            height={40}
            src="/images/logoFull.png"
          />
        </Box>
      </Content>
    </Section>
  );
};
