import { createReducer } from '@reduxjs/toolkit';

import { actions } from 'state/actions';

export interface UiState {
  loading: boolean;
}

const initialState: UiState = {
  loading: false,
};

export const uiReducer = createReducer<UiState>(initialState, builder => {
  builder.addCase(actions.ui.setLoading, (state, action) => {
    state.loading = action.payload;
  });
});
