import { User } from 'firebase/auth';

import { AppUser } from 'types/user';

export const mapToAppUser = (user: User): AppUser => ({
  uid: user.uid,
  createdAt: new Date().toISOString(),
  status: 'free',
  email: user.email,
  updatedAt: null,
  cancelled: null,
  reason: null,
  providers: user.providerData || null,
});

export const isUserValid = (user: AppUser | null) => {
  return user && typeof user.uid === 'string' && typeof user.email === 'string';
};
