import { createAction } from '@reduxjs/toolkit';

import { AppUser } from 'types/user';

enum UserActions {
  AUTH_GITHUB = 'user/AUTH_GITHUB',
  AUTH_GOOGLE = 'user/AUTH_GOOGLE',
  EMAIL_LOGIN = 'user/EMAIL_LOGIN',
  EMAIL_REGISTER = 'user/EMAIL_REGISTER',
  EXTERNAL_AUTH = 'user/EXTERNAL_AUTH',
  SET_USER = 'user/SET_USER',
}

export const setUser = createAction<AppUser | null>(UserActions.SET_USER);
export const externalAuth = createAction<string>(UserActions.EXTERNAL_AUTH);
export const signInWithGithub = createAction(UserActions.AUTH_GITHUB);
export const signInWithGoogle = createAction(UserActions.AUTH_GOOGLE);
export const signUpWithEmail = createAction<{
  email: string;
  password: string;
}>(UserActions.EMAIL_REGISTER);

export const loginWithEmail = createAction<{
  email: string;
  password: string;
}>(UserActions.EMAIL_LOGIN);
