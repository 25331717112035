import styled from 'styled-components';

import { Flex } from 'components/layout/Flex';

export const Content = styled(Flex).attrs({
  paddingX: { _: '2rem', md: '1rem' },
  maxWidth: { _: '100%', lg: '1024px' },
})`
  width: 100%;
  margin: 0 auto;
`;
