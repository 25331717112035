import styled from 'styled-components';
import {
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { Theme } from 'styles/theme';

export interface Props<T = Theme>
  extends TypographyProps<T>,
    LayoutProps<T>,
    SpaceProps<T>,
    PositionProps<T>,
    ColorProps<T> {
  disabled?: boolean;
  textDecoration?: 'line-through' | 'none' | 'blink' | string;
  whiteSpace?: 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | string;
  textTransform?:
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'none'
    | 'full-width'
    | 'full-size-kana'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset';
}

const typographyProps = compose(typography, layout, color, space, position);

export const Text = styled('p')<Props<Theme>>`
  display: block;
  color: ${p => p.color || p.theme.colors.text};
  font-family: ${p => p.theme.fonts.primary};
  opacity: ${p => (p.disabled ? 0.4 : 'initial')};
  text-transform: ${p => p.textTransform || 'initial'};
  text-decoration: ${p => p.textDecoration || 'initial'};
  white-space: ${p => p.whiteSpace || 'initial'};

  > b {
    font-family: ${p => p.theme.fonts.primary};
  }

  && {
    ${typographyProps}
  }
`;
