import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';

import { Flex } from 'components/layout/Flex';

import { Content } from './Content';
import { Section } from './Section';

export const Footer: FC = () => (
  <Section as="footer">
    <Content flexDirection="column" paddingY="2rem">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ _: 'column', sm: 'row' }}
      >
        <Flex gap="1rem" alignItems="center" flexDirection="column">
          <Image
            alt="Logo"
            width={220}
            height={60}
            src="/images/footerLogo.png"
          />
        </Flex>
        <Flex flexDirection="column" justifyContent="space-between">
          <Flex gap="0.5rem" marginY="1rem" flexDirection="column">
            <Link passHref href="/terms">
              <A>Terms of Service</A>
            </Link>
            <Link passHref href="/privacy">
              <A>Privacy Policy</A>
            </Link>
            {/* <Link passHref href="/faq">
              <A>FAQ</A>
            </Link> */}
          </Flex>
        </Flex>
      </Flex>
      {/* <PaymentMethods /> */}
    </Content>
  </Section>
);

const A = styled.a`
  padding: 0.2rem;
  white-space: nowrap;
  text-decoration: none;
  color: ${p => p.theme.colors.text40};
  font-family: ${p => p.theme.fonts.primary};

  &:hover {
    color: ${p => p.theme.colors.text90};
  }
`;
