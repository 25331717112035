import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import { Footer } from 'components/layout/Footer';
import { Header } from 'components/layout/Header';
import { store } from 'state/store';
import { GlobalStyle, theme } from 'styles/theme';

const App = ({ Component, pageProps, router }: AppProps) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM !== 'DEMO_GTM') {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM as string,
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn('GTM is disabled');
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header />
        <Component {...pageProps} query={router.query} />
        <Footer />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
