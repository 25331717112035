import styled from 'styled-components';
import {
  alignSelf,
  AlignSelfProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flex,
  FlexProps,
  gridArea,
  GridAreaProps,
  gridColumn,
  GridColumnProps,
  gridRow,
  GridRowProps,
  justifySelf,
  JustifySelfProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  zIndex,
  ZIndexProps,
} from 'styled-system';

import { Theme } from 'styles/theme';

const boxProps = compose(
  space,
  color,
  zIndex,
  layout,
  position,
  justifySelf,
  alignSelf,
  gridColumn,
  gridRow,
  gridArea,
  flex,
  border,
  shadow,
  textAlign,
);

interface Props<T = Theme>
  extends BorderProps<T>,
    PositionProps<T>,
    ColorProps<T>,
    ZIndexProps<T>,
    GridRowProps<T>,
    GridColumnProps<T>,
    GridAreaProps<T>,
    JustifySelfProps<T>,
    AlignSelfProps<T>,
    FlexProps<T>,
    LayoutProps<T>,
    SpaceProps<T>,
    ShadowProps<T>,
    TextAlignProps<T> {}

export const Box = styled.div<Props<Theme>>`
  && {
    ${boxProps};
  }
`;
