import { createReducer } from '@reduxjs/toolkit';

import { actions } from 'state/actions';
import { AppUser } from 'types/user';

export interface UserState {
  user: AppUser | null;
}

const initialState: UserState = {
  user: null,
};

export const userReducer = createReducer<UserState>(initialState, builder => {
  builder.addCase(actions.user.setUser, (state, action) => {
    state.user = action.payload;
  });
});
