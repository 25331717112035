import { loadStripe } from '@stripe/stripe-js';
import { call, select } from 'typed-redux-saga';

import { AnalyticsUA } from 'api/GoogleUA';
import { AppState } from 'state/store';
import { handleError } from 'utils/sentry';
import { createUrl } from 'utils/url';

const selector = (state: AppState) => ({
  user: state.user.user,
  product: state.payment.product,
});
export function* goToPayment() {
  const { user, product } = yield* select(selector);
  const stripe = yield* call(
    loadStripe,
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string,
  );

  if (stripe && user && product) {
    AnalyticsUA.trackBeginCheckout(product);
    const { error } = yield* call(stripe.redirectToCheckout, {
      mode: 'subscription',
      customerEmail: user.email || undefined,
      clientReferenceId: user.uid,
      cancelUrl: createUrl('checkout', { uid: user.uid }),
      successUrl: createUrl('success', {
        uid: user.uid,
        productId: product.stripeId,
      }),
      lineItems: [
        {
          price: product.stripeId,
          quantity: 1,
        },
      ],
    });

    if (error) {
      handleError(error);
    }
  }
}
