import { createAction } from '@reduxjs/toolkit';

import { IProduct } from 'types/payments';

enum PaymentActions {
  GOTO_PAYMENT = 'user/GOTO_PAYMENT',
  SET_PRODUCT = 'user/SET_PRODUCT',
}

export const goToPayment = createAction(PaymentActions.GOTO_PAYMENT);
export const setProduct = createAction<IProduct>(PaymentActions.SET_PRODUCT);
