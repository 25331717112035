import axios from 'axios';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';

import { AppUser } from 'types/user';
import { mapToAppUser } from 'utils/user';

class FirebaseClass {
  private readonly app: FirebaseApp;
  private readonly auth: Auth;

  static isFirebaseError = (e: unknown) => {
    return e && typeof e === 'object' && 'code' in e;
  };

  constructor() {
    const firebaseConfig = JSON.parse(
      process.env.NEXT_PUBLIC_FIREBASE_CONFIG || '',
    );
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
  }

  get = async (endpoint: string, params: string) => {
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/${endpoint}`;
    return axios.get(url + `${params || ''}`);
  };

  post = async (endpoint: string, data: object) => {
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/${endpoint}`;
    return axios.post(url, data);
  };

  fetchUser = async (uid: string): Promise<AppUser> => {
    const res = await this.get(`fetchUser`, `?uid=${uid}`);
    return res.data;
  };

  createUser = async (user: AppUser): Promise<AppUser> => {
    const res = await this.post(`createUser`, user);
    return res.data;
  };

  checkIfUserExists = async (uid: string): Promise<AppUser | null> => {
    try {
      const res = await this.get(`fetchUser`, `?uid=${uid}`);
      return res.data;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  };

  signInWithGithub = async (): Promise<AppUser> => {
    const provider = new GithubAuthProvider();
    const res = await signInWithPopup(this.auth, provider);
    return mapToAppUser(res.user);
  };

  signInWithGoogle = async (): Promise<AppUser> => {
    const provider = new GoogleAuthProvider();
    const res = await signInWithPopup(this.auth, provider);
    return mapToAppUser(res.user);
  };

  loginWithEmail = async (
    email: string,
    password: string,
  ): Promise<AppUser> => {
    const res = await signInWithEmailAndPassword(this.auth, email, password);
    return mapToAppUser(res.user);
  };

  signUpWithEmail = async (
    email: string,
    password: string,
  ): Promise<AppUser> => {
    const res = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password,
    );
    return mapToAppUser(res.user);
  };
}

export const Firebase = new FirebaseClass();
