export const createUrl = (path: string, params?: Record<string, string>) => {
  if (typeof window === 'undefined') return '';

  const searchParams = params ? new URLSearchParams(params).toString() : '';
  const url = `${window.location.protocol}//${window.location.host}/${path}`;

  return searchParams ? `${url}?${searchParams}` : url;
};

export const getParams = () => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    const keys = Array.from(params.keys());
    return keys.reduce((acc, key) => {
      acc[key] = params.get(key);
      return acc;
    }, {} as { [key: string]: string | null });
  }
  return {};
};
