import styled from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';

import { Theme } from 'styles/theme';

import { Box } from './Box';

export interface FlexProps extends FlexboxProps<Theme> {
  gap?: string;
}

export const Flex = styled(Box)<FlexProps>`
  display: flex;
  && {
    gap: ${p => p.gap};
    ${flexbox};
  }
`;
