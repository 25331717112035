/* eslint-disable @typescript-eslint/no-explicit-any */
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    margin:0;
    box-sizing:border-box;
  }
  html, body {
    margin: unset;
    height: 100vh;
    background-color: #ffffff;
  }

  div[id="__next"] {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
  }
`;

export const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    transparent: 'transparent',

    text: '#18181B',
    pillText: 'rgb(78, 79, 220)',
    pillBg: 'rgb(225, 231, 253)',
    subHeadingText: '#52525B',
    text90: 'rgba(0,0,0,0.9)',
    text60: 'rgba(34, 34, 34, 0.6)',
    text40: 'rgba(34, 34, 34, 0.4)',
    text30: 'rgba(34, 34, 34, 0.3)',
    linkText: '#4A6EE0',

    main: '#87d068',
    accent: '#4C8BF5',
    secondaryAccent: 'yellow',
    error: '#FF5A5F',

    bgLight: '#f3f3f3',
    bgHeader: 'rgba(255, 255, 255, 0.5)',

    authButtonBg: 'rgba(0, 0, 0, 0.05)',
    authButtonHoverBg: 'rgba(0, 0, 0, 0.1)',

    inputBg: 'rgba(0, 0, 0, 0.05)',
    inputHoverBg: 'rgba(0, 0, 0, 0.1)',

    hover: 'rgba(255,255,255, 0.2)', // TODO: rename
  },
  fonts: {
    primary: `'Inter', sans-serif`,
  },
  fontSizes: {
    hero3: '3rem',
    hero25: '2.5rem',
    hero2: '2rem',
    big15: '1.5rem',
    big175: '1.75rem',
    regular: '1.25rem',
    regular1: '1rem',
    regular1125: '1.125rem',
    small875: '0.875rem',
    small75: '0.75rem',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
  lineHeights: {
    tiny: 0.8,
    smaller: 0.9,
    normal: 1,
    bigger: 1.2,
    big: 1.5,
    biggest: 1.8,
  },
  radii: {
    circle: '50%',
    four: '4px',
    eight: '8px',
    ten: '10px',
    twenty: '20px',
  },
  shadows: {
    none: 'none',
  },
  zIndices: {
    z0: 0,
    z1: 1,
    z99: 99,
  },
  breakpoints: [
    '640px', // @media (min-width: 640px)
    '768px', // @media (min-width: 768px)
    '1024px', // @media (min-width: 1024px)
    '1280px', // @media (min-width: 1280px)
    '1536px', // @media (min-width: 1536px)
  ] as unknown as Breakpoints,
  letterSpacings: '' as any,
  space: [] as (string | number)[] | Breakpoints<string>,
} as const;

const Breakpoints = theme.breakpoints as any;
Breakpoints.sm = Breakpoints[0];
Breakpoints.md = Breakpoints[1];
Breakpoints.lg = Breakpoints[2];

export type Theme = typeof theme;

type Breakpoints<T = string> = {
  _: T;
  sm: T;
  md: T;
  lg: T;
  xl: T;
  xxl: T;
};
