import { createReducer } from '@reduxjs/toolkit';

import { actions } from 'state/actions';
import { IProduct } from 'types/payments';

export interface PaymentState {
  product: IProduct | null;
}

const initialState: PaymentState = {
  product: null,
};

export const paymentReducer = createReducer<PaymentState>(
  initialState,
  builder => {
    builder.addCase(actions.payment.setProduct, (state, action) => {
      state.product = action.payload;
    });
  },
);
