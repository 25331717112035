export type GaCurrency = 'USD' | string;

export enum UaEvent {
  purchase = 'ee.purchase',
  begin_checkout = 'ee.checkout_begin',
}

interface EventActionField {
  id?: string;
  tax?: string;
  affiliation?: string;
  revenue?: string;
  shipping?: string;
  coupon?: string;
  step?: number;
}

interface EcommerceProduct {
  id?: string;
  name: string;
  price: string;
  brand: string;
  category: string;
  variant: string;
  quantity: number;
  coupon?: string;
}
export interface EcommerceEvent {
  event: UaEvent;
  ecommerce: {
    currencyCode: GaCurrency;
    purchase?: {
      actionField: EventActionField;
      products: EcommerceProduct[];
    };
    checkout?: {
      actionField: EventActionField;
      products: EcommerceProduct[];
    };
  };
}
