import { call, put } from 'typed-redux-saga';

import { Firebase } from 'api/Firebase';
import { actions } from 'state/actions';
import { AppUser } from 'types/user';
import { handleError, maybeShowToast, setUserContext } from 'utils/sentry';

export function* fetchUser() {
  yield* put(actions.ui.setLoading(true));
  try {
    if (typeof window !== 'undefined') {
      const uid = new URLSearchParams(window.location.search).get('uid');
      if (uid) {
        const user = yield* call(Firebase.fetchUser, uid);
        if (user?.uid) {
          setUserContext({
            uid: user.uid,
            email: user.email,
          });
          yield put(actions.user.setUser(user));
        }
      }
    }
  } catch (e) {
    handleError(e);
  } finally {
    yield* put(actions.ui.setLoading(false));
  }
}

function* updateUserState(providerUser: AppUser, dbUser: AppUser | null) {
  if (dbUser) {
    yield* put(actions.user.setUser(dbUser));
  } else {
    const newUser = yield* call(Firebase.createUser, providerUser);
    yield put(actions.user.setUser(newUser));
  }
}

export function* signInWithGithub() {
  yield* put(actions.ui.setLoading(true));
  try {
    const user = yield* call(Firebase.signInWithGithub);
    const dbUser = yield* call(Firebase.checkIfUserExists, user.uid);
    yield* updateUserState(user, dbUser);
  } catch (e) {
    maybeShowToast(e);
    handleError(e);
  } finally {
    yield* put(actions.ui.setLoading(false));
  }
}

export function* signInWithGoogle() {
  yield* put(actions.ui.setLoading(true));
  try {
    const user = yield* call(Firebase.signInWithGoogle);
    const dbUser = yield* call(Firebase.checkIfUserExists, user.uid);
    yield* updateUserState(user, dbUser);
  } catch (e) {
    maybeShowToast(e);
    handleError(e);
  } finally {
    yield* put(actions.ui.setLoading(false));
  }
}

type RegisterAction = ReturnType<typeof actions.user.signUpWithEmail>;
export function* signUpWithEmail(action: RegisterAction) {
  const { email, password } = action.payload;
  yield* put(actions.ui.setLoading(true));
  try {
    const user = yield* call(Firebase.signUpWithEmail, email, password);
    const dbUser = yield* call(Firebase.checkIfUserExists, user.uid);
    yield* updateUserState(user, dbUser);
  } catch (e) {
    maybeShowToast(e);
    handleError(e);
  } finally {
    yield* put(actions.ui.setLoading(false));
  }
}

type LoginAction = ReturnType<typeof actions.user.loginWithEmail>;
export function* loginWithEmail(action: LoginAction) {
  const { email, password } = action.payload;
  yield* put(actions.ui.setLoading(true));
  try {
    const user = yield* call(Firebase.loginWithEmail, email, password);
    const dbUser = yield* call(Firebase.checkIfUserExists, user.uid);
    yield* updateUserState(user, dbUser);
  } catch (e) {
    maybeShowToast(e);
    handleError(e);
  } finally {
    yield* put(actions.ui.setLoading(false));
  }
}
